import React, { useEffect } from 'react'
import * as style from '../pages/index.module.css'
import * as styleInput from './Login.module.css'
import { navigate } from 'gatsby'

import Button from './Button'

const Login = () => {
  const handleSaveLogin = function (event) {
    const inputValue = event.target.elements.agentId.value

    if (typeof window !== 'undefined') {
      localStorage.setItem('agentId', inputValue)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('agentId') !== null) {
      navigate('/contact-form/')
    }
  })

  return (
    <section>
      <article className={style.container}>
        <header>
          <h2>Inicio de sesion</h2>
        </header>
        <form className={style.loginContainer} id="login" onSubmit={handleSaveLogin} autoComplete="off">
          <div className={styleInput.loginTextContainer}>
            <label className={styleInput.loginTextLabel} htmlFor='agentId'>
              Login de Agente: <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              autoFocus
              className={styleInput.loginText}
              type='number'
              name='agentId'
              required
              min="10000"
              max="99999"
              step="1"
            />
            <label className={styleInput.hintTextLabel}>
              IMPORTANTE: El login de Agente es el login que
              utilizas para conectar a Presence CTI (pcoagent)
            </label>
          </div>
          <div style={{ display: 'flex', marginTop: '2em' }} />
          <Button innerProps={{ form: 'login' }} type="submit">
            Guardar
          </Button>
        </form>
      </article>
    </section>
  )
}

export default Login
